var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "" } },
    [
      !_vm.api.isSuccesful
        ? _c(
            "v-card",
            {
              staticClass: "d-flex justify-center mx-auto white rounded-lg",
              attrs: {
                "elevation-1": "",
                "min-width": "400px",
                "min-height": "400px",
                outlined: "",
              },
            },
            [
              this.userFound
                ? _c(
                    "v-form",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { "x-large": "", color: "primary" } },
                            [_vm._v(" mdi-account-key ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center text-h3 my-3" },
                        [_vm._v(" Reset Password ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center my-3 mx-auto" },
                        [_vm._v(" " + _vm._s(_vm.form.email) + " ")]
                      ),
                      _c("hr"),
                      _c("AError", {
                        staticClass: "my-2",
                        attrs: { api: this.api },
                      }),
                      _c("v-text-field", {
                        staticClass: "mx-4 mt-6",
                        attrs: {
                          "append-icon": _vm.showPassword
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.showPassword ? "text" : "password",
                          dense: "",
                          outlined: "",
                          label: "password",
                          required: "",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showPassword = !_vm.showPassword
                          },
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "mx-4 ",
                        attrs: {
                          "append-icon": _vm.showConfirmPassword
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.showConfirmPassword ? "text" : "password",
                          dense: "",
                          outlined: "",
                          required: "",
                          label: "confirm password",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showConfirmPassword = !_vm.showConfirmPassword
                          },
                        },
                        model: {
                          value: _vm.confirmPassword,
                          callback: function ($$v) {
                            _vm.confirmPassword = $$v
                          },
                          expression: "confirmPassword",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center mx-auto mb-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: this.api.isLoading,
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updatePassword()
                                },
                              },
                            },
                            [_vm._v(" Confirm ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: { color: "primary", plain: "" },
                            },
                            [_vm._v(" Clear ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.api.isSuccesful
        ? _c(
            "v-card",
            { staticClass: "mx-auto" },
            [
              _c("v-img", {
                staticClass: "ma-7",
                attrs: { src: "/images/taxpod_vision_logo.png", width: "auto" },
              }),
              _c("hr"),
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("v-icon", { attrs: { size: "100", color: "green" } }, [
                    _vm._v(" mdi-check-circle "),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "d-flex justify-center text-h4 mb-2" },
                [
                  _vm._v(" Congratulations "),
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(" mdi-creation "),
                  ]),
                ],
                1
              ),
              _c("v-card-subtitle", { staticClass: "d-flex justify-center" }, [
                _vm._v(" Succesfully setting up your Password"),
                _c("br"),
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-center font-weight-bold" },
                [_vm._v(" Enjoy your VISION ")]
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c("v-btn", { attrs: { color: "primary", href: "/home" } }, [
                    _vm._v(" Go to home page "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }